<template>
  <div>
      <ul>
          <li><span><strong>{{$moment(date).format('YYYY-MM-DD')}}</strong></span> - <span>$moment(date).format('YYYY-MM-DD')</span></li>
          <li><span><strong>{{$moment(date).format('YYYY-MM-DD')}}</strong></span> - <span>$moment(date).format('YYYY-MM-DD')</span></li>
            <li><span><strong>{{$moment(date).add(2, 'hours').format('DD/MM/YYYY')}}</strong></span> - <span>$moment(date).add(2, 'hours').format('DD/MM/YYYY')</span></li>
            <li><span><strong>{{$moment(date).add(2, 'hours').format('DD/MM/YYYY')}}</strong></span> - <span>$moment(date).add(2, 'hours').format('DD/MM/YYYY')</span></li>
            <li><span><strong>{{$moment(date).startOf('day').format('DD/MM/YYYY')}}</strong></span> - <span>$moment(date).startOf('day').format('DD/MM/YYYY')</span></li>
            <li><span><strong>{{$moment(date).format('DD/MM/YY')}}</strong></span> - <span>$moment(date).format('DD/MM/YY')</span></li>
           <li><span><strong>{{$moment(date).format('LS')}}</strong></span> - <span>$moment(date).format(LS)</span></li>
           <li><span><strong>{{$moment(date).format('LL')}}</strong></span> - <span>$moment(date).format(LL)</span></li>
            <li><span><strong>{{$moment(date).format('LL LS')}}</strong></span> - <span>$moment(date).format(LL LS)</span></li>
            <li><span><strong>{{$moment(date).fromNow()}}</strong></span> - <span>$moment(date).fromNow()</span></li>
             <li><span><strong>{{$moment(date).fromNow(true)}}</strong></span> - <span>$moment(date).fromNow(true)</span></li>

      </ul>

      <p>
          The present definition includes the following<br>
        <strong>LTS  : </strong>'h:mm:ss A', <br>
        <strong>LT   : </strong>'h:mm A',<br>
        <strong>L    : </strong>'MM/DD/YYYY',<br>
        <strong>LL   : </strong>'MMMM D, YYYY',<br>
        <strong>LLL  : </strong>'MMMM D, YYYY h:mm A',<br>
        <strong>LLLL : </strong>'dddd, MMMM D, YYYY h:mm A'<br>

You can use any of these or change the input passed into moment().format(). For example, for your case you can pass moment.utc(dateTime).format('MMMM D, YYYY').
      </p>
  </div>
</template>

<script>
export default {
  created() {
    this.getView()
  },
  methods: {
    getView() {
            this.$http.get('/ViewAccess/devteamdates')
            .then(() => {
            })
            .catch(() => {
            })
        },
  }
}
</script>

<style>

</style>